import React, { useReducer } from 'react';
import { Order, OrderState } from '../../types/orderTypes';
import OrderContext from './order-context';
import orderReducer from './order-reducer';
import { CREATE_ORDER, GET_ORDER, GET_USER_ORDERS } from '../../helper/orders/orders';

interface props {
	children: JSX.Element | JSX.Element[];
}

const OrderProvider = ({ children }: props) => {
	const initialState: OrderState = {
		OrderDetails: {
			id: '',
			checkoutId: '',
			status: '',
			fulfillmentStatus: '',
			financialStatus: '',
			userDetails: {},
			billingAddress: {},
			shippingAddress: {},
			lineItems: [],
			totalAmount: '',
			discounts: [],
			discountedAmount: '',
			originalAmount: '',
			totalTaxesAmount: '',
		},
		orders: [],
	};
	const [globalState, dispatch] = useReducer(orderReducer, initialState);

	const createOrder = async (checkoutId: string, token: string, paymentMethod: string) => {
		const resCreateOrder = await window.fetch(`${process.env.GATSBY_API_URL}/orders/`, {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
				Authorization: token,
			},
			body: JSON.stringify({
				query: CREATE_ORDER,
				variables: {
					id: checkoutId,
					paymentMethod,
				},
			}),
		});
		try {
			const responseData = await resCreateOrder.json();
			const orderData = responseData?.data?.createOrder;
			return orderData;
		} catch (error) {
			console.log(error);
		}
	};

	const getOrder = async (orderId: string, token: string) => {
		const resGetOrder = await window.fetch(`${process.env.GATSBY_API_URL}/orders/`, {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
				Authorization: token,
			},
			body: JSON.stringify({
				query: GET_ORDER,
				variables: {
					id: orderId,
				},
			}),
		});
		try {
			const responseData = await resGetOrder.json();
			const orderData = responseData?.data?.getOrder;
			return orderData;
		} catch (error) {
			console.log(error);
		}
	};

	async function getOrdersByUser(token: string) {
		const response = await window.fetch(`${process.env.GATSBY_API_URL}/users/`, {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
				Authorization: token,
			},
			body: JSON.stringify({
				query: GET_USER_ORDERS,
			}),
		})
		try {
			const { data: { getUserOrders } } = await response.json();
			return getUserOrders as Order[];
		} catch (error) {
			return error;
		}
	}

	return (
		<OrderContext.Provider
			value={{
				createOrder,
				getOrder,
				getOrdersByUser,
			}}
		>
			{children}
		</OrderContext.Provider>
	);
};

export default OrderProvider;
