import React, { useContext, useReducer, useState } from 'react';
import {
	NewUserAddress,
	NewUserBillingAddress,
	NewUserShippingAddress,
	UserDetails,
	UserState,
	UpdateUserCreditInput,
	UserCreditDetails,
} from 'types/userTypes';
import UserContext from './user-context';
import userReducer from './user-reducer';
import { getCurrentUser, fetchAuthSession, signOut, updateUserAttributes } from 'aws-amplify/auth';
import { Amplify } from 'aws-amplify';
import config from '../../config/amplifyconfiguration.json';
import {
	CREATE_ADDRESS,
	CREATE_BILLING_ADDRESS,
	CREATE_SHIPPING_ADDRESS,
	GET_USER_ADDRESSES,
	GET_USER,
	UPDATE_USER,
	GET_USER_CREDIT,
	ADD_USER_CREDIT,
	UPDATE_USER_CREDIT,
} from '../../helper/user/get-user';
import { LocalCartContext } from '../../provider/local-cart-provider';
import { removeLocalStateItem } from '../../helper/local-storage';
import { navigate } from 'gatsby';
import { phoneCountryCodes } from '../../constants';

Amplify.configure(config);

interface props {
	children: JSX.Element | JSX.Element[];
}

const UserProvider = ({ children }: props) => {
	const { clearCart } = useContext(LocalCartContext);

	const initialState: UserState = {
		UserDetails: {
			username: '',
			email: '',
			token: '',
			phone_number: '',
			is_authenticated: false,
			userId: '',
			firstName: '',
			lastName: '',
			agronomist: '',
			country: '',
			avatar: '',
		},
		UserGeolocation: {
			geolocation: {
				lat: 0,
				lng: 0,
			},
		},
		UserCredit: {
			isActive: false,
			authorizedAmount: 0,
			usedAmount: 0,
			availableAmount: 0,
			feesAmount: 0,
			interestAmount: 0,
			financialProviderId: '',
			currency: '',
			creditId: '',
			externalId: '',
			status: '',
		},
	};
	const [globalState, dispatch] = useReducer(userReducer, initialState);
	const [isSessionExpired, setIsSessionExpired] = useState(false);
	const [messageSessionExpired, setMessageSessionExpired] = useState('');

	const getDbUser = async (token: string) => {
		const response = await window.fetch(`${process.env.GATSBY_API_URL}/users/`, {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
				Authorization: token,
			},
			body: JSON.stringify({
				query: GET_USER,
			}),
		});
		const { data } = await response.json();
		return data.getUser;
	};

	const getCurrentSession = async () => {
		try {
			const session = await fetchAuthSession();
			if (!session.tokens) return;
			const { idToken } = session.tokens ?? {};
			const userData = idToken?.payload;
			const email = userData?.email?.toString();
			const phone_number = userData?.phone_number?.toString();
			const token = idToken?.toString();
			await getUserAddress(token as string);
			const userId = userData?.sub?.toString();
			const { username } = await getCurrentUser();
			const { firstName, lastName, agronomist, country, avatar } = await getDbUser(
				token as string,
			);

			const userAttributes: UserDetails = {
				username,
				email,
				token,
				phone_number,
				is_authenticated: true,
				userId,
				firstName,
				lastName,
				agronomist,
				country,
				avatar,
			};
			dispatch({
				type: 'GET_CURRENT_USER',
				payload: userAttributes,
			});
			await getUserCredit(token as string);

			if (idToken?.payload?.exp && new Date() >= new Date(idToken?.payload.exp * 1000)) {
				setIsSessionExpired(true);
				setMessageSessionExpired('Tu sesión expiró. Por favor inicia sesión nuevamente.');
			}
			return userAttributes;
		} catch (error) {
			setIsSessionExpired(true);
			console.error(error);
		}
	};

	const handleSignOut = async () => {
		try {
			await signOut();
			clearCart();
			removeLocalStateItem('filters');
			const userAttributes: UserDetails = {
				username: '',
				email: '',
				token: '',
				phone_number: '',
				is_authenticated: false,
				userId: '',
				firstName: '',
				lastName: '',
				agronomist: '',
				country: '',
				avatar: '',
			};
			dispatch({
				type: 'GET_CURRENT_USER',
				payload: userAttributes,
			});
			navigate('/');
			if (location.pathname === '/') {
				location.reload();
			}
		} catch (error) {
			console.log(error);
		}
	};

	const getUserAddress = async (token: string) => {
		const resUserAddress = await window.fetch(`${process.env.GATSBY_API_URL}/users/`, {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
				Authorization: token,
			},
			body: JSON.stringify({
				query: GET_USER_ADDRESSES,
			}),
		});
		try {
			const {
				data: { getUserAddresses },
			} = await resUserAddress.json();
			const { addresses } = getUserAddresses;
			if (addresses.length === 0) {
				navigate('/tus-direcciones');
			}
			const geolocation = {
				geolocation: {
					lat: addresses[0]?.geolocation?.lat,
					lng: addresses[0]?.geolocation?.lng,
				},
			};
			dispatch({
				type: 'GET_GEOLOCATION_USER',
				payload: geolocation,
			});

			return getUserAddresses;
		} catch (error) {
			console.log(error);
		}
	};

	const createUserAddress = async (token: string, address: NewUserAddress) => {
		const resNewUserAddress = await window.fetch(`${process.env.GATSBY_API_URL}/users/`, {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
				Authorization: token,
			},
			body: JSON.stringify({
				query: CREATE_ADDRESS,
				variables: {
					input: address,
				},
			}),
		});

		try {
			const resNewAddress = await resNewUserAddress.json();
			const geolocation = {
				geolocation: {
					lat: resNewAddress?.data?.addUserAddress?.geolocation?.lat,
					lng: resNewAddress?.data?.addUserAddress?.geolocation?.lng,
				},
			};
			dispatch({
				type: 'GET_GEOLOCATION_USER',
				payload: geolocation,
			});
			return resNewAddress?.data?.addUserAddress;
		} catch (error) {
			console.log(error);
		}
	};

	const createBillingAddress = async (token: string, billingAddress: NewUserBillingAddress) => {
		const resNewUserAddress = await window.fetch(`${process.env.GATSBY_API_URL}/users/`, {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
				Authorization: token,
			},
			body: JSON.stringify({
				query: CREATE_BILLING_ADDRESS,
				variables: {
					input: billingAddress,
				},
			}),
		});

		try {
			const resNewBillingAddress = await resNewUserAddress.json();
			return resNewBillingAddress?.data;
		} catch (error) {
			console.log(error);
		}
	};

	const createShippingAddress = async (
		token: string,
		shippingAddress: NewUserShippingAddress,
	) => {
		const resNewUserAddress = await window.fetch(`${process.env.GATSBY_API_URL}/users/`, {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
				Authorization: token,
			},
			body: JSON.stringify({
				query: CREATE_SHIPPING_ADDRESS,
				variables: {
					input: shippingAddress,
				},
			}),
		});

		try {
			const resNewSippingAddress = await resNewUserAddress.json();
			return resNewSippingAddress?.data;
		} catch (error) {
			console.log(error);
		}
	};

	const updateUserData = async ({ newData }: { newData: UserDetails }) => {
		const { email: prevEmail, phone_number: prevPhone, token } = globalState.UserDetails;
		const { email, phone_number, country } = newData;

		const attributesToUpdate: { [key: string]: string } = {};
		if (email && email !== prevEmail) {
			attributesToUpdate.email = email;
		}
		if (phone_number && phone_number !== prevPhone) {
			const formattedPhone = `${phoneCountryCodes[country as string]}${phone_number}`;
			attributesToUpdate.phone_number = formattedPhone;
		}

		if (Object.keys(attributesToUpdate).length > 0) {
			try {
				await updateUserAttributes({ userAttributes: attributesToUpdate });
			} catch (error) {
				console.error(error);
				return error;
			}
		}

		const { firstName, lastName, agronomist } = newData;

		const result = await window.fetch(`${process.env.GATSBY_API_URL}/users/`, {
			method: 'post',
			body: JSON.stringify({
				query: UPDATE_USER,
				variables: {
					input: {
						firstName,
						lastName,
						agronomist,
						country,
						email: attributesToUpdate.email || prevEmail,
						phone: attributesToUpdate.phone_number || prevPhone,
					},
				},
			}),
			headers: {
				'Content-Type': 'application/json',
				Authorization: token as string,
			},
		});
		const {
			data: { updateUser },
		} = await result.json();
		const newUserData = {
			...globalState.UserDetails,
			...updateUser,
			phone_number: updateUser.phone,
		};
		dispatch({
			type: 'GET_CURRENT_USER',
			payload: newUserData,
		});
	};

	const uploadAvatar = async (file: string) => {
		const { token } = globalState.UserDetails;
		const result = await window.fetch(`${process.env.GATSBY_API_URL}/users/avatar`, {
			method: 'post',
			body: file,
			headers: {
				'Content-Type': 'application/json',
				Authorization: token as string,
			},
		});
		const data = await result.json();
		const res = await window.fetch(`${process.env.GATSBY_API_URL}/users/`, {
			method: 'post',
			body: JSON.stringify({
				query: UPDATE_USER,
				variables: {
					input: {
						avatar: data.avatarUrl,
					},
				},
			}),
			headers: {
				'Content-Type': 'application/json',
				Authorization: token as string,
			},
		});
		const {
			data: {
				updateUser: { avatar },
			},
		} = await res.json();
		const newUserData = {
			...globalState.UserDetails,
			avatar,
		};
		dispatch({
			type: 'GET_CURRENT_USER',
			payload: newUserData,
		});
	};

	async function getUserCredit(token?: string) {
		try {
			if (token === '') throw new Error('You must be logged in to can get your data')
			const options = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: token as string,
				},
				body: JSON.stringify({ query: GET_USER_CREDIT }),
				redirect: 'follow' as RequestRedirect,
			};
			const response = await window.fetch(`${process.env.GATSBY_API_URL}/users/`, options);
			const parsedRes = await response.json();
			if (Object.hasOwn(parsedRes, 'error'))
				throw new Error('Something went wrong while getting data');
			const {
				data: { getUserCredit },
			} = parsedRes;
			const availableAmount = Number((
				Number(getUserCredit.authorizedAmount.value) - Number(getUserCredit.usedAmount.value)
			).toFixed(2))
			const newUserCredit = {
				isActive: getUserCredit.status === 'ACTIVE' ? true : false,
				authorizedAmount: Number(getUserCredit.authorizedAmount.value),
				usedAmount: Number(getUserCredit.usedAmount.value),
				availableAmount,
				feesAmount: Number(getUserCredit.feesAmount.value),
				interestAmount: Number(getUserCredit.interestAmount.value),
				financialProviderId: getUserCredit.financialProviderId as string,
				currency: getUserCredit.authorizedAmount.currency as string,
				creditId: getUserCredit.id as string,
				externalId: getUserCredit.creditId as string,
				status: getUserCredit.status as string,
			};
			dispatch({
				type: 'GET_USER_CREDIT',
				payload: newUserCredit
			});
			return newUserCredit
		} catch (error) {
			console.log(error);
			return error;
		}
	}

	async function addUserCredit(financialProviderId: string) {
		const { token, email } = globalState.UserDetails;
		try {
			const variables = {
				input: {
					userEmail: email,
					financialProviderId,
				},
			};
			const response = await window.fetch(`${process.env.GATSBY_API_URL}/orders/`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: token || '',
				},
				body: JSON.stringify({ query: ADD_USER_CREDIT, variables }),
				redirect: 'follow',
			});
			const parsedRes = await response.json();
			if (Object.hasOwn(parsedRes, 'error')) {
				throw new Error('An error occurred while saving data');
			}
			const {
				data: { addUserCredit },
			} = parsedRes;
			const userCreditDetails = {
				isActive: addUserCredit.status === 'ACTIVE' ? true : false,
				financialProviderId,
				currency: addUserCredit.authorizedAmount.currency,
				authorizedAmount: addUserCredit.authorizedAmount.value,
				usedAmount: addUserCredit.usedAmount.value,
				availableAmount:
					addUserCredit.authorizedAmount.value - addUserCredit.usedAmount.value,
				feesAmount: addUserCredit.feesAmount.value,
				interestAmount: addUserCredit.interestAmount.value,
				creditId: addUserCredit.id,
				externalId: addUserCredit.creditId,
			};
			dispatch({
				type: 'GET_USER_CREDIT',
				payload: userCreditDetails,
			});
		} catch (error) {
			return error;
		}
	}

	async function updateUserCredit(input: UpdateUserCreditInput) {
		const { token } = globalState.UserDetails;
		const { creditId } = globalState.UserCredit;
		try {
			const variables = {
				input: {
					id: creditId,
					creditId: input.creditId,
					currency: input.currency,
					authorizedAmount: input.authorizedAmount,
					usedAmount: input.usedAmount,
					feesAmount: input.feesAmount,
					interestAmount: input.interestAmount,
					status: input.status,
				},
			};
			const response = await window.fetch(`${process.env.GATSBY_API_URL}/orders/`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: token || '',
				},
				body: JSON.stringify({ query: UPDATE_USER_CREDIT, variables }),
				redirect: 'follow',
			});
			const parsedRes = await response.json();
			if (Object.hasOwn(parsedRes, 'error')) {
				throw new Error('An error occurred while updating data');
			}
			const {
				data: { updateUserCredit },
			} = parsedRes;
			const { UserCredit } = globalState;
			const creditDetail = {
				...UserCredit,
				currency:
					UserCredit.currency !== updateUserCredit.authorizedAmount.currency &&
					updateUserCredit.authorizedAmount.currency,
				authorizedAmount:
					UserCredit.authorizedAmount !== updateUserCredit.authorizedAmount.value &&
					updateUserCredit.authorizedAmount.value,
				usedAmount:
					UserCredit.usedAmount !== updateUserCredit.usedAmount.value &&
					updateUserCredit.usedAmount.value,
				feesAmount:
					UserCredit.feesAmount !== updateUserCredit.feesAmount.value &&
					updateUserCredit.feesAmount.value,
				interestAmount:
					UserCredit.interestAmount !== updateUserCredit.interestAmount.value &&
					updateUserCredit.interestAmount.value,
				isActive: updateUserCredit.status === 'ACTIVE' ? true : false,
				availableAmount:
					updateUserCredit.authorizedAmount.value - updateUserCredit.usedAmount.value,
				externalId:
					UserCredit.externalId !== updateUserCredit.creditId &&
					updateUserCredit.creditId,
			};
			dispatch({
				type: 'GET_USER_CREDIT',
				payload: creditDetail,
			});
		} catch (error) {
			return error;
		}
	}

	return (
		<UserContext.Provider
			value={{
				userDetails: globalState.UserDetails,
				userGeolocation: globalState.UserGeolocation,
				userCredit: globalState.UserCredit,
				getCurrentSession,
				getUserAddress,
				handleSignOut,
				messageSessionExpired,
				isSessionExpired,
				createUserAddress,
				createBillingAddress,
				createShippingAddress,
				updateUserData,
				uploadAvatar,
				getUserCredit,
				addUserCredit,
				updateUserCredit,
			}}
		>
			{children}
		</UserContext.Provider>
	);
};

export default UserProvider;
