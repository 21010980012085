export const CREATE_ORDER = `
mutation createOrder($id: ID!, $paymentMethod: String) {
    createOrder(checkoutId: $id, paymentMethod: $paymentMethod) {
        id
        number
        checkoutId
        fulfilledAt
        paidAt
        status
        statusModifications {
            status
            createdAt
        }
        fulfillmentStatus
        financialStatus
        email
        phone
        user {
            id
            firstName
            lastName
        }
        billingAddress {
            address {
                id
                name
                street
                streetNumber
                internalNumber
                neighborhood
                city
                state
                country
                zipCode
                geolocation {
                    lat
                    lng
                }
            }
            legalName
            taxId
        }
        shippingAddress {
            address {
                id
                name
                street
                streetNumber
                internalNumber
                neighborhood
                city
                state
                country
                zipCode
                geolocation {
                    lat
                    lng
                }
            }
            email
            phone
        }
        requiresInvoice
        lineItems {
            name
            sku
            product {
                id
                name
                description
                mainImageUrl
            }
            productVariant {
                id
                name
                amount {
                    value
                }
            }
            quantity
            provider {
                id
                name
            }
        }
        totalAmount {
            value
        }
        discounts {
            id
            code
            amount {
                value
            }
        }
        discountedAmount {
            value
        }
        originalAmount {
            value
        }
        totalTaxesAmount {
            value
        }
        discountedTaxesAmount {
            value
        }
        originalTaxesAmount {
            value
        }
        shippingAmount {
            value
        }
        createdAt
        updatedAt
    }
}
`;

export const GET_ORDER = `
query getOrder($id: ID!) {
    getOrder(id: $id) {
        id
        number
        checkoutId
        fulfilledAt
        paidAt
        status
        statusModifications {
            status
            createdAt
        }
        fulfillmentStatus
        financialStatus
        email
        phone
        user {
            id
            firstName
            lastName
            document
        }
        billingAddress {
            address {
                id
                name
                street
                streetNumber
                internalNumber
                neighborhood
                city
                state
                country
                zipCode
                geolocation {
                    lat
                    lng
                }
            }
            legalName
            taxId
        }
        shippingAddress {
            address {
                id
                name
                street
                streetNumber
                internalNumber
                neighborhood
                city
                state
                country
                zipCode
                geolocation {
                    lat
                    lng
                }
            }
            email
            phone
        }
        requiresInvoice
        lineItems {
            name
            sku
            product {
                id
                name
                description
                mainImageUrl
            }
            productVariant {
                id
                name
                amount {
                    value
                }
            }
            quantity
            provider {
                id
                name
            }
        }
        totalAmount {
            value
        }
        discounts {
            id
            code
            amount {
                value
            }
        }
        discountedAmount {
            value
        }
        originalAmount {
            value
        }
        totalTaxesAmount {
            value
        }
        discountedTaxesAmount {
            value
        }
        originalTaxesAmount {
            value
        }
        shippingAmount {
            value
        }
        createdAt
        updatedAt
    }
}
`;

export const GET_USER_ORDERS = `query getUserOrders {
    getUserOrders {
        id
        number
        checkoutId
        fulfilledAt
        paidAt
        status
        statusModifications {
            status
            createdAt
        }
        fulfillmentStatus
        financialStatus
        email
        phone
        user {
            id
            firstName
            lastName
        }
        billingAddress {
            address {
                id
                name
                street
                streetNumber
                internalNumber
                neighborhood
                city
                state
                country
                zipCode
                geolocation {
                    lat
                    lng
                }
            }
            legalName
            taxId
        }
        shippingAddress {
            address {
                id
                name
                street
                streetNumber
                internalNumber
                neighborhood
                city
                state
                country
                zipCode
                geolocation {
                    lat
                    lng
                }
            }
            email
            phone
        }
        requiresInvoice
        lineItems {
            id
            name
            sku
            product {
                id
                name
                description
                mainImageUrl
                brand
                category
                activeIngredients
                cropTypes
            }
            productVariant {
                id
                name
                amount {
                    value
                }
            }
            quantity
            provider {
                id
                name
                email
                phone
            }
        }
        totalAmount {
            value
            currency
        }
        discounts {
            id
            code
            amount {
                value
            }
        }
        discountedAmount {
            value
        }
        originalAmount {
            value
        }
        totalTaxesAmount {
            value
        }
        discountedTaxesAmount {
            value
        }
        originalTaxesAmount {
            value
        }
        shippingAmount {
            value
        }
        createdAt
        updatedAt
    }
}`;