import { createContext } from 'react';
import { Category, CategoryProduct } from '../../types/categoryTypes';

export type CategoryContextProps = {
	categories: Array<Category>;
	productDetails: Array<CategoryProduct>;
	getCategories: Function;
	getCategory: Function;
	getHomeProducts: Function;
	getProductVariantsByProvider: Function;
	getFinancialProviders: Function;
};

const CategoryContext = createContext<CategoryContextProps>({} as CategoryContextProps);

export default CategoryContext;
